<template>
  <div class="all-content">
    <div class="contentbox boxShow">
      <div
        class="box-head"
        :class="{
          paddingTop115:
            (boxItem.open_status == 0 && !show && !notOrder) || boxItem.open_status == 1
        }"
      >
        <div
          class="t-c"
          v-if="(boxItem.open_status == 0 && !show && !notOrder) || boxItem.open_status == 1"
        >
          <img class="wid165" src="@/imgs/icon/box_get.png" alt="" />
        </div>
        <div
          class="t-c subtitle"
          v-if="(boxItem.open_status == 0 && !show) || boxItem.open_status == 1"
        >
          {{ boxItem.display_name }}
          <div class="title_box">
            {{
              boxItem.items_name
                ? boxItem.items_name
                : boxItem.stage == 0
                ? '盲盒'
                : boxItem.stage + '期盲盒'
            }}
            <span v-if="boxnum > 1"> x {{ boxnum }}</span>
            <div class="sending-box" v-if="boxArr[swipeIndex].status == 2">转赠中</div>
          </div>
          <!-- <img class="wid243" src="@/imgs/comm/Slice14.png" alt="" /> -->
        </div>
        <div class="close-icon" @click="closeBox">
          <img src="@/imgs/icon/icon_tc_gb.png" alt="" />
        </div>
      </div>
      <div class="box-wraper" v-if="!show">
        <!-- <div class=""></div> -->
        <div class="box-swapper">
          <van-swipe
            class=""
            :loop="false"
            ref="swipebox"
            @change="swipeChange"
            :show-indicators="false"
          >
            <van-swipe-item v-for="(item, index) in boxArr" :key="index">
              <div class="box-content">
                <van-image
                  @load="loading = false"
                  src="https://activity-resource.321mh.com/nft/blindbox/bindbox.gif"
                ></van-image>
              </div>
              <div class="t-c box-bottom">
                <div class="good-btn" v-if="item.status != 2" @click="getCard(item, index)">
                  开盲盒
                </div>
                <!-- 点击任意位置开盲盒 -->
              </div>
            </van-swipe-item>
          </van-swipe>
          <div class="gift-l" v-if="boxArr.length > 1" @click="moveClick(1, 1)">
            <img :src="swipeIndex == 0 ? icon_gre_left : icon_box_left" alt="" />
          </div>
          <div class="gift-r" v-if="boxArr.length > 1" @click="moveClick(2, 1)">
            <img :src="swipeIndex == boxArr.length - 1 ? icon_gre_right : icon_box_right" alt="" />
          </div>
        </div>
        <!-- <van-image @load="loading = false" @click="getCard" :src="require('@/imgs/comm/box.gif')"></van-image> -->
      </div>

      <!-- <van-popup v-model:show="show" style=" overflow: visible n ;"> -->
      <div class="swipecard-box">
        <van-swipe
          class="swipecard-box-swipe"
          :loop="false"
          ref="swiper"
          v-if="show"
          :show-indicators="false"
          @change="swipeChange"
        >
          <van-swipe-item v-for="(item, index) in cardList" :key="index">
            <div class="wraper">
              <div class="pop" :class="paused ? 'paused' : ''">
                <div class="card-bg">
                  <div class="num" v-if="item.items_type == 1">
                    编号：{{ item.token_id + '-' + item.serial_num }}
                  </div>
                  <div
                    class="t-c"
                    :class="item.items_name && item.items_name.length > 4 ? 'name-nobg' : 'name'"
                  >
                    <span class="span-name">{{ item.items_name }}</span>
                  </div>
                  <div class="t-c card-box">
                    <img class="wid238" :src="item.image_icon" alt="" />
                    <div class="sending" v-if="item.status == 2">转赠中</div>
                    <div class="sending learning" v-if="item.regular_status == 1">修炼中</div>
                    <div class="t-c tag">
                      <img v-if="item.items_type == 1" :src="levelsChange(item.levels)" alt="" />
                    </div>
                    <!-- <van-image width="238"></van-image> -->
                  </div>

                  <div class="card-bt t-c">
                    <span>{{ item.sub_name }}</span>
                  </div>
                </div>
                <div class="close" v-if="item.privilege_type == 0">
                  <div class="t-c" @click="close(item)">查看藏品详情<van-icon name="arrow" /></div>
                  <div class="t-c" @click="toSmarket(item)">
                    查看市场行情<van-icon name="arrow" />
                  </div>
                </div>
                <div class="finishLearn" v-if="item.regular_status == 1">
                  <span>
                    <van-count-down
                      class="time"
                      millisecond
                      format="HH:mm:ss"
                      :time="diffTime(item.end_time)"
                      @change="e => changeTime(e, item, index)"
                  /></span>
                  结束修炼
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
        <div class="gift-l" v-if="cardList.length > 1" @click="moveClick(1, 2)">
          <img :src="swipeIndex == 0 ? icon_gre_left : icon_card_left" alt="" />
        </div>
        <div class="gift-r" v-if="cardList.length > 1" @click="moveClick(2, 2)">
          <img :src="swipeIndex == cardList.length - 1 ? icon_gre_right : icon_card_right" alt="" />
        </div>
      </div>

      <van-popup
        class="vantPop"
        v-model:show="showpopup"
        closeable
        :close-on-click-overlay="false"
        close-icon-position="top-right"
      >
        <div class="popup">
          <div class="content">
            <div class="title">恭喜获得</div>
            <div class="desc">
              <div class="imgbox">
                <img :src="card.image_icon" alt="" />
              </div>
              <div class="">
                {{ card.reward_type == 2 ? '积分' : '红包' }} x
                <span
                  >{{ card.reward_type == 6 ? card.reward_num / 100 : card.reward_num }}
                  {{ card.reward_type == 6 ? '元' : '' }}</span
                >
              </div>
            </div>
          </div>
          <div class="btn">
            <div @click="closeBox">知道了</div>
          </div>
        </div>
      </van-popup>

      <PageLoading :show="loading" />
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, getCurrentInstance, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import {
  checkorderispay,
  opencollection,
  getusercollectionlist,
  getwishdetailid
} from '@/service/home';
import { Toast, SwipeInstance } from 'vant';
import { nftUtils, localStorageUtil } from '@/utils';
import PageLoading from '@/components/loading/PageLoading.vue';
import moment from 'moment';

export default {
  emits: ['close'],
  components: {
    PageLoading
  },
  props: {
    order: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: 0
    },
    notOrder: {
      type: Boolean,
      default: false
    },
    itemsData: {
      type: Object
    },
    trans_num: {
      type: Number,
      default: 0
    },
    server_time: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const route = proxy.$route;
    const state = reactive({
      img1: 'https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1652341341261.jpg',
      show: true,
      paused: false,
      orderNum: '', //'2022051715091710',
      card: {},
      loading: true,
      boxArr: [],
      boxItem: {},
      boxIndex: 0,
      cardList: [],
      swiper: null,
      swipebox: null,
      boxnum: 0, //藏品数量
      swipeIndex: 0,
      startIndex: 0,
      itemsData: props.itemsData,
      showpopup: false
      //  boxStadus: 1 //1--正常，2--转赠中 ，盲盒
    });
    watch(
      () => props.itemsData,
      itemsData => {
        state.itemsData = itemsData;
      }
    );
    onMounted(async () => {
      localStorageUtil.del('orderId');
      state.orderNum = props.order; // route.params.id;
      // const { data } = await checkorderispay({ trade_no: state.orderNum });
      // // window.scroll(0, 0);
      // if (data) {
      //   // 开盲盒
      // } else {
      //   // 未支付
      //   Toast('该订单未支付');
      //   setTimeout(() => {
      //     router.push({ path: `/order`, replace: true });
      //   }, 1000);
      //}
      if (!props.notOrder) {
        const { data } = await getusercollectionlist({ trade_no: state.orderNum });
        state.boxArr = data;
        state.boxItem = state.boxArr[0];
        state.boxnum = data.length || 1;
        // console.log(state.boxArr);
        if (state.boxItem.open_status == 1) {
          //  非盲盒，正常购买
          state.cardList = data;
          state.loading = false;
          setTimeout(() => {
            state.paused = true;
          }, 950);
        } else {
          state.show = false;
        }
      } else {
        console.log(props.trans_num, '....trans_num');
        state.boxArr = state.itemsData;

        // 我的藏品页面,判断是否为卡片查看
        if (state.itemsData[0].open_status == 1) {
          //卡片查看
          state.cardList = state.itemsData;
          state.loading = false;
          setTimeout(() => {
            state.paused = true;
          }, 950);
        } else {
          state.boxItem = state.boxArr[0];
          state.boxnum = state.boxArr.length || 1;
          //  state.boxStadus = state.boxItem.status;
          // 藏品中心盲盒查看
          state.show = false;
        }
      }
    });
    const swipeChange = index => {
      state.swipeIndex = index;
    };

    const toSmarket = async item => {
      const { data } = await getwishdetailid({ token_id: item.token_id });
      // console.log(item);

      location.href = 'https://market.genscmeta.com/2022/NFTmarket/marketDetail?id=' + data;
    };
    // 图片移动事件
    //const refMeth = ref('swiper');
    const moveClick = (direction, type) => {
      // direction--1,left 2,right,type--1,盲盒，2--卡片

      if (type == 1) {
        //盲盒
        direction == 1 ? state.swipebox.prev() : state.swipebox.next();
      } else {
        //卡片 swipecard
        direction == 1 ? state.swiper.prev() : state.swiper.next();
      }
    };
    const closeBox = () => {
      // 需要判断状态
      if (state.boxItem.open_status == 0) {
        //盲盒
        if (!state.show && !state.showpopup) {
          // 盲盒未开启，直接关闭弹窗
          ctx.emit('close', true);
        } else {
          if (state.boxArr.length > 1) {
            // 还有多个盲盒
            state.paused = false;
            if (!state.showpopup) {
              state.show = !state.show;
            }
            state.boxArr.splice(state.boxIndex, 1);
            state.boxnum = state.boxArr.length;
            // 关闭积分、红包弹窗
            state.showpopup = false;
          } else {
            ctx.emit('close', true);
          }
        }
      } else {
        //非盲盒
        ctx.emit('close');
      }
      //
    };
    const getCard = async (item, index) => {
      if (item.status == 2) {
        Toast('转赠中藏品不能开启');
        return;
      }
      state.boxIndex = index;
      let postdata = { id: item.id }; //trade_no
      // console.log(postdata);
      // return;
      const res = await opencollection(postdata);
      if (res.status == 0) {
        // 判断是否为盲盒
        state.card = res.data;
        if (res.data.reward_type == 2 || res.data.reward_type == 6) {
          // 积分和红包
          state.showpopup = true;
        } else {
          state.cardList = [res.data];

          state.show = !state.show;
        }
      } else {
        Toast(res.message);
      }
      nftUtils.setcnzzRecordByBtn('开盲盒');
      setTimeout(() => {
        state.paused = true;
      }, 900);
    };
    const close = item => {
      console.log(item);
      nftUtils.setcnzzRecordByBtn('关闭卡片');
      router.push({
        path: '/collectionsDetail',
        query: {
          id: item.id
        }
      }); //replace: true
      // if (window.location.href.indexOf('/collections') > -1) {
      //   ctx.emit('close', true);
      // } else {

      // }
    };
    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(state.server_time), 'millisecond');
    };

    const changeTime = (val, item, index) => {
      if (val.total == 0) {
        state.cardList[index].regular_status = 0;
      }
    };

    return {
      ...toRefs(state),
      getCard,
      levelsChange,
      close,
      closeBox,
      moveClick,
      swipeChange,
      diffTime,
      changeTime,
      toSmarket,
      icon_box_left: require('../imgs/icon/icon_b_l.png'),
      icon_box_right: require('../imgs/icon/icon_b_r.png'),
      icon_card_left: require('../imgs/icon/icon_y_l.png'),
      icon_card_right: require('../imgs/icon/icon_y_r.png'),
      icon_gre_left: require('../imgs/icon/left_gre.png'),
      icon_gre_right: require('../imgs/icon/right_gre.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;

  .content {
    padding: 16px 20px 20px 20px;

    .title {
      font-size: 18px;
      font-weight: 500;
    }
    .desc {
      font-weight: 400;
      margin-top: 20px;
      .imgbox {
        text-align: center;
      }
      img {
        width: 48px;
        height: 48px;
      }
      span {
        color: $primary;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    // border-top: 1px solid #494359;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      width: 166px;
      height: 44px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid rgba(255, 255, 255, 0.7);
    }
  }
}
.finishLearn {
  position: absolute;
  bottom: -80px;
  width: 100%;
  width: 160px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  .time {
    display: inline-block;
    color: #58dedd;
  }
}

.box-head {
  font-size: 0px;
  // flex-grow: 0.3;
  padding-top: 15px;
  position: relative;
  margin-bottom: 20px;
  min-height: 90px;
}
.paddingTop115 {
  padding-top: 145px;
}
.contentbox {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // height: 650px;

  height: 100vh;
}
.boxShow {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.all-content {
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #1c172a;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: auto;
  // overflow-y: scroll;
}
.swipecard-box {
  position: relative;

  &-swipe {
    height: 650px;
  }

  .gift-l {
    position: absolute;
    z-index: 3;
    top: 218px;
    left: 8px;
    img {
      width: 31px;
      height: 31px;
    }
  }
  .gift-r {
    position: absolute;
    z-index: 3;
    top: 218px;
    right: 8px;
    img {
      width: 31px;
      height: 31px;
    }
  }
}
.close {
  position: absolute;
  bottom: -26px;
  width: 75%;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
}
.subtitle {
  width: 266px;
  height: 38px;
  background-image: url('../imgs/icon/box_bg.png');
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 13px;
  color: #fff;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  .title_box {
    font-size: 16px;
    line-height: 19px;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    .sending-box {
      padding: 2px 4px;
      background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      border-radius: 4px;
      color: #1c172a;
      font-weight: 500;
      margin-left: 10px;
      font-size: 10px;
    }
  }
}

.box-wraper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  // height: 100vh;
  //flex-grow: 1;
}
.close-icon {
  font-size: 0px;
  position: fixed;
  z-index: 999;
  right: 19px;
  top: 19px;
  img {
    width: 22px;
    height: 22px;
  }
}
.good-btn {
  position: relative;
  display: flex;

  width: 272px;
  height: 45px;
  justify-content: center;
  align-items: center;
  background-image: url('../imgs/comm/pic_button_cmh.png');
  background-size: 100%;
  color: $primaryc;
}
.box-bottom {
  padding-top: 21px;
  font-size: 16px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
}
.box-swapper {
  position: relative;
  .gift-l {
    position: absolute;
    z-index: 3;
    top: 172px;
    left: 14px;
    img {
      width: 31px;
      height: 31px;
    }
  }
  .gift-r {
    position: absolute;
    z-index: 3;
    top: 172px;
    right: 14px;
    img {
      width: 31px;
      height: 31px;
    }
  }
}
.box-content {
  font-size: 0px;
  flex-grow: 2;

  img {
    width: 100%;
  }
}

.wid285 {
  width: 285px;
}
.wid165 {
  width: 115px;
  margin-top: 15px;
}
.wid243 {
  width: 243px;
  margin-top: 12px;
}
.wraper {
  //   position: absolute;
  // top: 40%;
  // left: 50%;
  transform: translate(0%, 0%);
  // text-align: center;
}
.pop {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  transform: scale(0, 0);
  animation: mymove 1s infinite;
  -webkit-animation: mymove 1s infinite; /* Safari and Chrome */
  // transform: translate(0%, 0%);
}
.paused {
  animation-play-state: paused;
  transform: scale(1, 1) !important;
}
@keyframes mymove {
  0% {
    transform: scale(0, 0) rotateY(0deg);
  }
  100% {
    transform: scale(1, 1) rotateY(360deg);
  }
}

@-webkit-keyframes mymove {
  /* Safari and Chrome */
  0% {
    transform: scale(0, 0) rotateY(0deg);
  }
  100% {
    transform: scale(1, 1) rotateY(360deg);
  }
}
.van-popup {
}

.card-box {
  font-size: 0px;
  position: relative;
  margin-bottom: 14px;
  .sending {
    position: absolute;
    padding: 2px 4px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px;
    color: #1c172a;
    font-weight: 500;
    z-index: 9;
    font-size: 10px;
    top: 15px;
    left: 44px;
  }

  .learning {
    background: linear-gradient(132deg, #fff0bc 0%, #ffd057 100%);
    top: 40px;
  }
}
.tag {
  position: absolute;
  bottom: 30px;
  margin: 0 auto;
  width: 100%;
  // margin: 14px 0 17px;
  font-size: 0px;
  img {
    width: 60px;
  }
}
.wid238 {
  width: 238px;
  font-size: 0px;
}
.num {
  line-height: 12px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.card-bt {
  font-size: 16px;
  font-weight: bold;
  font-family: DFSongGB Std-W12, DFSongGB Std;
  background-image: -webkit-linear-gradient(bottom, #a78b5f, #ffeccd, #ffc466);

  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;
}
.t-c {
  text-align: center;
}
.name {
  margin: 10px auto;
  width: 236px;
  height: 34px;
  @include bis('../imgs/comm/Slice19.png');
  display: flex;
  // align-items: center;
  justify-content: center;
}
.name-nobg {
  margin: 10px auto;
  width: 236px;
  height: 34px;
  // @include bis("../imgs/comm/Slice19.png");
  display: flex;
  // align-items: center;
  justify-content: center;
}
.span-name {
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;

  font-family: DFSongGB-W12;
  background-image: -webkit-linear-gradient(bottom, #a78b5f, #ffeccd, #ffc466);
  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;
}
.card-bg {
  @include bis('../imgs/comm/Slice17.png');
  width: 292px;
  //   height: 457px;
  box-sizing: border-box;
  padding: 13px 0 39px;
}
</style>
